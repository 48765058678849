import React from 'react';
import { t } from '../../localize';
import { Boxes, Noshis } from '../../consts';

type Props = {
  formValues: {
    box: string;
    kind: string;
    familyName: string;
    naire1: string;
    naire2: string;
    naire3: string;
    usuzumi: boolean;
  };
  showUsuzumi: boolean;
  isOpen: boolean;
  close: () => void;
  onClickUpdate: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickReset: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeForm: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
};

export const Modal: React.FC<Props> = ({
  formValues,
  showUsuzumi,
  isOpen,
  close,
  onClickUpdate,
  onClickReset,
  onChangeForm,
}) => {
  const isOpenClassName = isOpen ? '' : 'hidden';
  return (
    <>
      <div
        id='noshi_edit_mask'
        className={isOpenClassName}
        onClick={close}
      ></div>
      <section id='noshi_edit_modal' className={isOpenClassName}>
        <div className='title'>
          <h3>
            {t('noshiModalEditNoshi')}
            <span>{t('noshiModalEditNoshiEn')}</span>
          </h3>
          <div id='noshi_edit_close1' onClick={close}></div>
        </div>
        <table>
          <tbody>
            <tr>
              <th>{t('noshiModalSelectBox')}</th>
              <td>
                <select
                  value={formValues.box}
                  onChange={onChangeForm}
                  name='box'
                >
                  {Boxes.map((box, index) => (
                    <option key={index} value={box}>
                      {box}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>{t('noshiModalNoshiKind')}</th>
              <td>
                <select
                  value={formValues.kind}
                  onChange={onChangeForm}
                  name='kind'
                >
                  {Noshis.map((noshiKind) => (
                    <option key={noshiKind} value={noshiKind}>
                      {noshiKind}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>{t('noshiModalFamilyName')}</th>
              <td>
                <input
                  type='text'
                  placeholder={t('noshiModalFamilyNamePlaceholder')}
                  id='n_myoji'
                  name='familyName'
                  value={formValues.familyName}
                  onChange={onChangeForm}
                />
              </td>
            </tr>
            <tr>
              <th>{t('noshiModalNaire1')}</th>
              <td>
                <input
                  type='text'
                  placeholder={t('noshiModalNaire1Placeholder')}
                  id='n_naire1'
                  name='naire1'
                  value={formValues.naire1}
                  onChange={onChangeForm}
                />
              </td>
            </tr>
            <tr>
              <th>{t('noshiModalNaire2')}</th>
              <td>
                <input
                  type='text'
                  placeholder={t('noshiModalNaire2Placeholder')}
                  id='n_naire2'
                  name='naire2'
                  value={formValues.naire2}
                  onChange={onChangeForm}
                />
              </td>
            </tr>
            <tr>
              <th>{t('noshiModalNaire3')}</th>
              <td>
                <input
                  type='text'
                  placeholder={t('noshiModalNaire3Placeholder')}
                  id='n_naire3'
                  name='naire3'
                  value={formValues.naire3}
                  onChange={onChangeForm}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div id='usuzumi_row' style={{ display: showUsuzumi ? '' : 'none' }}>
          {t('noshiModalUsuzumi')}
          <label className='check_label'>
            <input
              name='usuzumi'
              className='input_check'
              type='checkbox'
              checked={formValues.usuzumi}
              onChange={onChangeForm}
            />
            <span className='input_dummy' />
          </label>
        </div>
        <div className='bt'>
          <button type='button' className='wait' onClick={onClickUpdate}>
            {t('noshiUpdate')}
          </button>
          <button type='button' className='reset' onClick={onClickReset}>
            {t('noshiReset')}
          </button>
          <button
            type='button'
            className='close'
            id='noshi_edit_close2'
            onClick={close}
          >
            {t('noshiCancel')}
          </button>
        </div>
      </section>
    </>
  );
};
