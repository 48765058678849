import React from 'react';
import { t } from '../../../localize';
import { AmplifyUser } from '@aws-amplify/ui';
import { useAuthenticator } from '@aws-amplify/ui-react';

type Props = {
  user: AmplifyUser;
  onClickLogout: () => void;
};

export const HeaderComponent: React.FC<Props> = (props) => {
  const { user } = useAuthenticator((context) => [context.user]);
  return (
    <header className='op_header'>
      <div className='inner'>
        <h1>
          <a href='/private/dashboard'>
            <img src='/assets/img/logo.svg' alt={t('commonHeaderLogo')} />
          </a>
        </h1>
        <input type='checkbox' id='drawer' />
        <label htmlFor='drawer' className='open'></label>
        <label htmlFor='drawer' className='close'></label>
        <nav className='menu'>
          <h2>{t('commonHeaderMenu')}</h2>
          <div className='info'>
            <h3>{t('commonHeaderUserInfo')}</h3>
            <dl>
              <dt>
                <img
                  src='/assets/img/icon/user.svg'
                  alt={t('commonHeaderName')}
                />
              </dt>
              <dd>
                {user.username}
                {/*<span>{email}</span>*/}
              </dd>
            </dl>

            <button
              type='button'
              className='submit'
              onClick={props.onClickLogout}
            >
              {t('commonHeaderLogout')}
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
};
