import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<Navigate to={'/private'} />} />
        <Route path={'/private/*'} element={<PrivateRoute />} />
        <Route path={'/*'} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export const NotFound: React.FC = () => <>{'Not found.'}</>;

export default App;
