import { lang } from './ja';

export const translate = (
  key: string,
  placeholder?: { [key: string]: string }
): string => {
  if (lang[key]) {
    let result = lang[key];
    if (placeholder) {
      Object.keys(placeholder).forEach((k) => {
        result = result.replace(`{${k}}`, placeholder[k]);
      });
    }
    return result;
  }
  console.error(`Missing translate key. ${key}`);
  return '';
};

export const t = (
  key: string,
  placeholder?: { [key: string]: string }
): string => {
  return translate(key, placeholder);
};
