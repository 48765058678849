import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../localize';

export const Top: React.FC = () => {
  return (
    <main className='SR_main'>
      <div className='SR_container'>
        <div className='SR_title'>
          <h2>{t('topTitle')}</h2>
        </div>

        <article className='SR_admin'>
          <section>
            <h3>{t('topNoshiTitle')}</h3>
            <ul>
              <li>
                <Link className='btA' to={'/private/noshi'}>
                  {t('topGoToNoshi')}
                </Link>
              </li>
            </ul>
          </section>
        </article>
      </div>
    </main>
  );
};
