export type PrintSize = 'A3' | 'A4' | 'B4';

export type BoxPrintSizeCombination = {
  printSize: PrintSize;
  box: Box;
  classPrefix: string;
};

export const Boxes = [
  '紙管L',
  '紙管M',
  '紙管S',
  'グランサイズ',
  'ドゥミサイズ',
  'プチカレサイズ',
  'グレーBOX_M',
  'グレーBOX_L',
  'yrBOX_M',
  'yrBOX_L',
  'MQ_GGBOX',
  'Many_BOX',
  'プラケース（デコールなど）',
  'トリバコーヒー専用BOX',
  'たまごパック',
] as const;
export type Box = (typeof Boxes)[number];

export const Noshis = [
  '紅白・花結（御祝）',
  '紅白・花結（御出産御祝）',
  '紅白・花結（御礼）',
  '紅白・花結（内祝）',
  '紅白・花結（御中元）',
  '紅白・花結（御歳暮）',
  '紅白・花結（御年賀）',
  '紅白・十本結切（寿）',
  '紅白・十本結切（御結婚御祝）',
  '紅白・十本結切（結婚内祝）',
  '紅白・五本結切（御見舞）',
  '紅白・五本結切（快気祝）',
  '黒白・七本結切（志）',
] as const;
export type Noshi = (typeof Noshis)[number];

export const NoshiKinds: Record<Noshi, string> = {
  '紅白・花結（御祝）': 'hana_iwi',
  '紅白・花結（御出産御祝）': 'hana_ssn',
  '紅白・花結（御礼）': 'hana_rei',
  '紅白・花結（内祝）': 'hana_uci',
  '紅白・花結（御中元）': 'hana_cgn',
  '紅白・花結（御歳暮）': 'hana_seb',
  '紅白・花結（御年賀）': 'hana_ong',
  '紅白・十本結切（寿）': '10mu_kbk',
  '紅白・十本結切（御結婚御祝）': '10mu_kkn',
  '紅白・十本結切（結婚内祝）': '10mu_uci',
  '紅白・五本結切（御見舞）': '05mu_mmi',
  '紅白・五本結切（快気祝）': '05mu_kki',
  '黒白・七本結切（志）': '07mu_kkr',
};

export const BoxPrintSizeCombinations: BoxPrintSizeCombination[] = [
  { printSize: 'B4', classPrefix: 'B4_bat_', box: '紙管L' },
  { printSize: 'A4', classPrefix: 'A4_fce_', box: '紙管M' },
  { printSize: 'A4', classPrefix: 'A4_pet_', box: '紙管S' },
  { printSize: 'B4', classPrefix: 'B4_std_', box: 'グランサイズ' },
  { printSize: 'A4', classPrefix: 'A4_dmi_', box: 'ドゥミサイズ' },
  { printSize: 'A4', classPrefix: 'A4_dec_', box: 'プチカレサイズ' },
  { printSize: 'A4', classPrefix: 'A4_std_', box: 'グレーBOX_M' },
  { printSize: 'A3', classPrefix: 'A3_std_', box: 'グレーBOX_L' },
  { printSize: 'B4', classPrefix: 'B4_035_', box: 'yrBOX_M' },
  { printSize: 'A3', classPrefix: 'A3_std_', box: 'yrBOX_L' },
  { printSize: 'A3', classPrefix: 'A3_std_', box: 'MQ_GGBOX' },
  { printSize: 'A3', classPrefix: 'A3_std_', box: 'Many_BOX' },
  {
    printSize: 'A4',
    classPrefix: 'A4_std_',
    box: 'プラケース（デコールなど）',
  },
  { printSize: 'A4', classPrefix: 'A4_std_', box: 'トリバコーヒー専用BOX' },
  { printSize: 'A4', classPrefix: 'A4_f50_', box: 'たまごパック' },
];
