export const lang: { [key: string]: string } = {
  commonHeaderLogo: 'PReSM',
  commonHeaderMenu: 'MENU',
  commonHeaderUserInfo: 'ユーザー情報',
  commonHeaderName: '名前',
  commonHeaderLogout: 'ログアウト',
  commonFooterPoweredBy: 'Powered by grooveOn Inc. 2022',

  topTitle: 'Showroom用ダッシュボード',
  topNoshiTitle: '熨斗印刷',
  topGoToNoshi: '熨斗選択へ進む',

  noshiTitle: 'Showroom 熨斗印刷',
  noshiPrintSize: '印刷サイズ：',
  noshiEdit: '編集する',
  noshiPrint: '印刷する',
  noshiDone: '作業終了',
  noshiControllerNaireAll: '名入れ全体',
  noshiControllerNaire1: '名入れ1',
  noshiControllerNaire2: '名入れ2',
  noshiControllerNaire3: '名入れ3',
  noshiControllerSmall: '小',
  noshiControllerBig: '大',
  noshiControllerClose: '×',
  noshiControllerUp: '▲',
  noshiControllerDown: '▼',
  noshiControllerLeft: '◀︎',
  noshiControllerRight: '▶︎',
  noshiModalSelectBox: '箱を選択',
  noshiModalEditNoshi: '熨斗の編集',
  noshiModalEditNoshiEn: 'EDIT NOSHI',
  noshiModalNoshiKind: '熨斗の種別',
  noshiModalFamilyName: '苗字',
  noshiModalNaire1: '名入れ1',
  noshiModalNaire2: '名入れ2',
  noshiModalNaire3: '名入れ3',
  noshiModalUsuzumi: '表書きと名入れを薄墨にする',
  noshiModalFamilyNamePlaceholder: '夫婦などで苗字を共通にする場合',
  noshiModalNaire1Placeholder: '名入れ1列目',
  noshiModalNaire2Placeholder: '名入れ2列目',
  noshiModalNaire3Placeholder: '名入れ3列目',
  noshiUpdate: '更新',
  noshiReset: 'リセット',
  noshiCancel: 'キャンセル',
};
