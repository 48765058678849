import { Amplify } from 'aws-amplify';
import logo from '../assets/react.svg';

import { Authenticator, translations } from '@aws-amplify/ui-react';
import { AmplifyUser } from '@aws-amplify/ui';
import '@aws-amplify/ui-react/styles.css';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { t } from '../localize';
import { HeaderComponent } from '../components/common/Header';
import { Top } from '../components/top';
import { NoshiContainer } from '../container/noshi';

const env = import.meta.env;

Amplify.configure({
  Auth: {
    region: env.VITE_AWS_REGION,
    userPoolId: env.VITE_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: env.VITE_AWS_COGNITO_CLIENT_ID,
    storage: localStorage,
  },
});
Amplify.I18n.putVocabularies(translations);
Amplify.I18n.setLanguage('ja');
Amplify.I18n.putVocabularies({
  ja: {
    'Enter your Username': 'ユーザー名を入力 ',
  },
});

const components = {
  Header: () => (
    <div style={{ textAlign: 'center' }}>
      <img src={logo} width={256} height={128} alt={t('commonHeaderLogo')} />
    </div>
  ),
  Footer: () => (
    <div style={{ textAlign: 'center' }}>
      <p>{t('commonFooterPoweredBy')}</p>
    </div>
  ),
};

export default function App() {
  return (
    <Authenticator variation='modal' components={components} hideSignUp={true}>
      {(props) => (
        <Routes>
          <Route
            path={'/'}
            element={<Layout user={props.user} signOut={props.signOut} />}
          >
            <Route index element={<Top />} />
            <Route path={'/noshi'} element={<NoshiContainer />} />
            <Route path={'/*'} element={<Navigate to={'/private/'} />} />
          </Route>
        </Routes>
      )}
    </Authenticator>
  );
}

const Layout = ({
  user,
  signOut,
}: {
  user?: AmplifyUser;
  signOut?: ((data?: any) => void) | undefined;
}) => {
  if (!signOut) return <></>;
  if (!user) return <>{'Loading...'}</>;
  return (
    <>
      <HeaderComponent user={user} onClickLogout={signOut} />
      <Outlet />
    </>
  );
};
